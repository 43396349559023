var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "h1",
      { class: _vm.$style.branchName },
      [
        _c("router-link", { attrs: { to: { name: "company" } } }, [
          _vm._v(_vm._s(_vm.companyName))
        ]),
        _vm._v(" " + _vm._s(_vm.branch.name) + "\n  ")
      ],
      1
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("section", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "pull-right mt-2" }, [
              _c(
                "div",
                { staticClass: "d-inline-block" },
                [
                  !_vm.loading
                    ? _c(
                        "a-dropdown",
                        { class: _vm.$style.operationBtn },
                        [
                          _c(
                            "span",
                            { staticClass: "ant-dropdown-link" },
                            [
                              _c("i", { staticClass: "icmn-cog mr-2" }),
                              _c(
                                "span",
                                { staticClass: "hidden-lg-down mr-2" },
                                [_vm._v("操作")]
                              ),
                              _c("a-icon", { attrs: { type: "down" } })
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c(
                                "a-menu-item",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "branchUpdate",
                                          params: { id: _vm.branch.id }
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icmn-pencil mr-2"
                                      }),
                                      _c("span", [_vm._v("編集")])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-menu-item",
                                { on: { click: _vm.showModal } },
                                [
                                  _c("i", { staticClass: "icmn-bin mr-2" }),
                                  _c("span", [_vm._v("削除")])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("a-modal", {
                    attrs: {
                      title: _vm.title,
                      visible: _vm.visible,
                      "confirm-loading": _vm.confirmLoading,
                      okText: "削除",
                      okType: "danger",
                      cancelText: "キャンセル"
                    },
                    on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
                  })
                ],
                1
              )
            ]),
            _c("h2", [_vm._v("基本情報")])
          ]),
          _c("div", { staticClass: "card-body" }, [
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "text-center" },
                  [_c("a-spin", { attrs: { tip: "Loading..." } })],
                  1
                )
              : _vm._e(),
            !_vm.loading
              ? _c("ul", { class: _vm.$style.basicInfoList }, [
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("名前: ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm.branch.name))
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("住所: ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm.branch.address))
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("備考: ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm.branch.comment))
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("作成日: ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm._f("moment")(_vm.branch.created_at)))
                    ])
                  ]),
                  _c("li", [
                    _c("span", { class: _vm.$style.infoLabel }, [
                      _vm._v("更新日: ")
                    ]),
                    _c("span", { class: _vm.$style.infoValue }, [
                      _vm._v(_vm._s(_vm._f("moment")(_vm.branch.updated_at)))
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "col-lg-6" },
        [
          _c("belong-users-list", {
            attrs: {
              belongUsersListLoading: _vm.belongUsersListLoading,
              users: _vm.users,
              branchId: _vm.branch.id
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-lg-6" },
        [
          _c("vehicles-list", {
            attrs: {
              vehiclesListLoading: _vm.vehiclesListLoading,
              vehicles: _vm.vehicles,
              branchId: _vm.branch.id,
              concatVehicleNameAndNo: _vm.concatVehicleNameAndNo
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "col-lg-12" },
      [
        _c("drive-data-list", {
          key: _vm.$route.fullPath + "_BranchDriveDataView",
          attrs: { target: "branch", targetId: _vm.branchId }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }