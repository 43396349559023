<template>
  <section class="card">
    <div class="card-header">
      <div class="pull-right mt-2">
        <div v-if="!vehiclesListLoading" class="d-inline-block">
          <router-link :to="{ name: 'vehicleCreate', params: { branch_id: branchId } }">
            <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">作成</a-button>
          </router-link>
          <router-link :to="{ name: 'vehicleCreateBulk' }">
            <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">一括作成</a-button>
          </router-link>
        </div>
      </div>
      <h2>車両一覧</h2>
    </div>
    <div class="card-body">
      <div v-if="vehiclesListLoading" class="text-center">
        <a-spin tip="Loading..." />
      </div>
      <div v-if="!vehiclesListLoading">
        <span class="mr-5">フィルター</span>
        <a-form layout="inline">
          <a-form-item label="車両名">
            <a-select
              mode="multiple"
              placeholder="車両名"
              :value="selectedVehiclesName"
              style="min-width:150px; margin-right: 12px;"
              @change="handleChangeVehicleName"
            >
              <a-select-option v-for="item in filteredOptionsVehicleName" :key="`name-${item.id}`" :value="`${item.id}-${item.name}`">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="車両番号">
            <a-select
              mode="multiple"
              placeholder="車両番号"
              :value="selectedVehiclesNumber"
              style="min-width:150px;"
              @change="handleChangeVehicleNumber"
            >
              <a-select-option v-for="item in filteredOptionsVehicleNumber" :key="`number-${item.id}`" :value="`${item.id}-${item.number}`">
                {{ item.number }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
        <hr style="margin: 20px 0 0 0">
        <a-list
          item-layout="horizontal"
          :data-source="filteredVehicles"
          :pagination="true"
        >
          <a-list-item slot="renderItem" slot-scope="item" :key="`data-${item.id}`">
            <router-link :to="{ name: 'vehicleShow', params: { id: item.id }}">
              <a-list-item-meta
                :description="item.comment"
              >
                <p slot="title" class="list-name">{{ concatVehicleNameAndNo ? item.name + ',' + item.number : item.name }}</p>
              </a-list-item-meta>
            </router-link>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </section>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
export default {
  props: [
    'vehiclesListLoading',
    'vehicles',
    'branchId',
    'concatVehicleNameAndNo',
  ],
  data() {
    return {
      selectedVehiclesName: [],
      selectedVehiclesNumber: [],
    }
  },
  computed: {
    filteredOptionsVehicleName() {
      return this.vehicles.filter(v => !this.selectedVehiclesName.includes(v))
    },
    filteredOptionsVehicleNumber() {
      return this.vehicles.filter(v => !this.selectedVehiclesNumber.includes(v))
    },
    selectedVehiclesNameValue() {
      return this.selectedVehiclesName.map(v => this.vehicles.find(target => target.id + '-' + target.name === v).name)
    },
    selectedVehiclesNumberValue() {
      return this.selectedVehiclesNumber.map(v => this.vehicles.find(target => target.id + '-' + target.number === v).number)
    },
    filteredVehicles() {
      if (!this.selectedVehiclesName.length && !this.selectedVehiclesNumber.length) return this.vehicles
      let vehicles = this.vehicles
      if (this.selectedVehiclesName.length) vehicles = vehicles.filter(v => this.selectedVehiclesNameValue.includes(v.name))
      if (this.selectedVehiclesNumber.length) vehicles = vehicles.filter(v => this.selectedVehiclesNumberValue.includes(v.number))
      return vehicles
    },
  },
  methods: {
    handleChangeVehicleName(selectedVehiclesName) {
      this.selectedVehiclesName = selectedVehiclesName
    },
    handleChangeVehicleNumber(selectedVehiclesNumber) {
      this.selectedVehiclesNumber = selectedVehiclesNumber
    },
  },
}
</script>
