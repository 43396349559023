<template>
  <section class="card">
    <div class="card-header">
      <div class="pull-right mt-2">
        <div v-if="!belongUsersListLoading" class="d-inline-block">
          <router-link :to="{ name: 'belongUserManage', params: { id: branchId }}">
            <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">管理</a-button>
          </router-link>
        </div>
      </div>
      <h2>所属ユーザー一覧</h2>
    </div>
    <div class="card-body">
      <div v-if="belongUsersListLoading" class="text-center">
        <a-spin tip="Loading..." />
      </div>
      <div v-if="!belongUsersListLoading">
        <span :class="$style.filterFormLabel">フィルター</span>
        <a-select
          mode="multiple"
          placeholder="権限"
          :value="selectedRoles"
          style="width: 40%"
          @change="handleChange"
        >
          <a-select-option v-for="item in filteredOptions" :key="item.value" :value="item.label">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <hr style="margin: 20px 0 0 0">
        <a-list
          item-layout="horizontal"
          :data-source="filteredUsers"
          :pagination="true"
          :class="$style.belongUsersList"
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta>
              <p slot="title" class="list-name">
                <a-tag :color="role[item.role].color">{{ role[item.role].label }}</a-tag>
                <router-link :to="{ name: 'userShow', params: { id: item.id }}">
                  <span v-if="myRole <= 1 && item.last_login === null"><i class="icmn-lock"></i></span> {{ item.name }}
                </router-link>
            </p>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </section>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import moment from 'moment'
import { enumData } from '@/services/enum'
import storeVue from '@/store'

const OPTIONS = enumData.userRole.filter(r => r.value !== 0)

export default {
  props: [
    'belongUsersListLoading',
    'users',
    'branchId',
  ],
  data() {
    return {
      selectedRoles: [],
      role: [],
      myRole: 3,
    }
  },
  computed: {
    filteredOptions() {
      return OPTIONS.filter(o => !this.selectedRoles.includes(o))
    },
    filteredUsers() {
      if (!this.selectedRoles.length) return this.users
      else return this.users.filter(u => this.selectedRolesValue.includes(u.role))
    },
    selectedRolesValue() {
      return this.selectedRoles.map(v => enumData.userRole.find(target => target.label === v).value)
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
  },
  created() {
    enumData.userRole.forEach(u => { this.role[u.value] = { label: u.label, color: u.tag } })
  },
  methods: {
    handleChange(selectedRoles) {
      this.selectedRoles = selectedRoles
    },
  },
  mounted() {
    this.myRole = storeVue.getters.role
  },
}
</script>
