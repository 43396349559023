var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "card" }, [
    _c("div", { staticClass: "card-header" }, [
      _c("div", { staticClass: "pull-right mt-2" }, [
        !_vm.vehiclesListLoading
          ? _c(
              "div",
              { staticClass: "d-inline-block" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "vehicleCreate",
                        params: { branch_id: _vm.branchId }
                      }
                    }
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-3",
                        class: _vm.$style.filledBtn,
                        attrs: {
                          type: "primary",
                          autoInsertSpaceInButton: "false"
                        }
                      },
                      [_vm._v("作成")]
                    )
                  ],
                  1
                ),
                _c(
                  "router-link",
                  { attrs: { to: { name: "vehicleCreateBulk" } } },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "ml-3",
                        class: _vm.$style.filledBtn,
                        attrs: {
                          type: "primary",
                          autoInsertSpaceInButton: "false"
                        }
                      },
                      [_vm._v("一括作成")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("h2", [_vm._v("車両一覧")])
    ]),
    _c("div", { staticClass: "card-body" }, [
      _vm.vehiclesListLoading
        ? _c(
            "div",
            { staticClass: "text-center" },
            [_c("a-spin", { attrs: { tip: "Loading..." } })],
            1
          )
        : _vm._e(),
      !_vm.vehiclesListLoading
        ? _c(
            "div",
            [
              _c("span", { staticClass: "mr-5" }, [_vm._v("フィルター")]),
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: "車両名" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: {
                            "min-width": "150px",
                            "margin-right": "12px"
                          },
                          attrs: {
                            mode: "multiple",
                            placeholder: "車両名",
                            value: _vm.selectedVehiclesName
                          },
                          on: { change: _vm.handleChangeVehicleName }
                        },
                        _vm._l(_vm.filteredOptionsVehicleName, function(item) {
                          return _c(
                            "a-select-option",
                            {
                              key: "name-" + item.id,
                              attrs: { value: item.id + "-" + item.name }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.name) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: "車両番号" } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { "min-width": "150px" },
                          attrs: {
                            mode: "multiple",
                            placeholder: "車両番号",
                            value: _vm.selectedVehiclesNumber
                          },
                          on: { change: _vm.handleChangeVehicleNumber }
                        },
                        _vm._l(_vm.filteredOptionsVehicleNumber, function(
                          item
                        ) {
                          return _c(
                            "a-select-option",
                            {
                              key: "number-" + item.id,
                              attrs: { value: item.id + "-" + item.number }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.number) +
                                  "\n            "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr", { staticStyle: { margin: "20px 0 0 0" } }),
              _c("a-list", {
                attrs: {
                  "item-layout": "horizontal",
                  "data-source": _vm.filteredVehicles,
                  pagination: true
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "renderItem",
                      fn: function(item) {
                        return _c(
                          "a-list-item",
                          { key: "data-" + item.id },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "vehicleShow",
                                    params: { id: item.id }
                                  }
                                }
                              },
                              [
                                _c(
                                  "a-list-item-meta",
                                  { attrs: { description: item.comment } },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "list-name",
                                        attrs: { slot: "title" },
                                        slot: "title"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.concatVehicleNameAndNo
                                              ? item.name + "," + item.number
                                              : item.name
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    }
                  ],
                  null,
                  false,
                  3479005090
                )
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }